<template lang="pug">
.stats
canvas.canvas(ref='canvas')
img.vooc(ref='img' src='~@/assets/vooc/vooc-text.jpg')
</template>

<script>
window.__DEBUG__ = false;

import { onBeforeUnmount, onMounted, ref } from 'vue';
import createREGL from 'regl';
import { initLightenCommand } from '@/commands/vooc/lighten/index.js';
import { loadImg, resize } from '../ImageEffect/common';

export default {
  name: 'Lighten',
  setup() {
    const canvas = ref(null);
    const img = ref(null);

    let regl;

    onMounted(async () => {
      resize(canvas.value);

      regl = createREGL({ canvas: canvas.value });

      let curPos = [0, 0];
      let prevPos = [0, 0];
      const res = [canvas.value.width, canvas.value.height];
      const dpr = window.devicePixelRatio;

      await loadImg(img.value);

      const { start, update } = initLightenCommand(regl, img.value);
      start({ res });

      const onMousemove = (e) => {
        prevPos[0] = curPos[0];
        prevPos[1] = curPos[1];
        curPos[0] = e.clientX;
        curPos[1] = window.innerHeight - e.clientY;

        update({
          res,
          curPos: curPos.map((v) => v * dpr),
          prevPos: prevPos.map((v) => v * dpr),
        });
      };

      window.addEventListener('mousemove', onMousemove);
    });

    onBeforeUnmount(() => regl?.destroy());

    return { canvas, img };
  },
};
</script>

<style lang="stylus" scoped>
@import '../ImageEffect/common.styl'
.vooc
  width 1020px
  height 190px
</style>
