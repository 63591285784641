import vert from './lighten.vert';
import frag from './lighten.frag';

/**
 * @param {ReturnType<createREGL>} regl
 */
export const initLightenCommand = (regl, image) => {
  const draw = regl({
    vert,
    frag,
    attributes: {
      a_position: [
        [-1, 1, 0],
        [-1, -1, 0],
        [1, -1, 0],
        [1, 1, 0],
      ],
    },
    uniforms: {
      u_texture: regl.texture({ data: image, flipY: true, min: 'nearest', mag: 'nearest' }),
      u_textureSize: [image.width, image.height].map((v) => v * window.devicePixelRatio),
      u_time: ({ time }) => time,
      u_res: (_, props) => props.res,
      u_mousePos: (_, props) => props.curPos,
      u_mousePrevPos: (_, props) => props.prevPos,
    },
    count: 4,
    primitive: 'triangle fan',
    blend: {
      enable: true,
      func: {
        src: 'src alpha',
        dst: 'one minus src alpha',
      },
    },
  });

  let updateData = {
    res: [0, 0],
    curPos: [0, 0],
    prevPos: [0, 0],
  };

  const update = (props) => {
    updateData = Object.assign(updateData, props);
  };

  const start = (props) => {
    update(props);
    regl.frame(() => {
      draw({
        ...updateData,
      });
    });
  };

  return { start, update };
};
